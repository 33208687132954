import { motion } from "framer-motion";
import { LoaderContainer } from "./styles";

const maxBarHeight = 76;
const minBarHeight = 31;
const barHeightPropertySeries = [maxBarHeight, minBarHeight, maxBarHeight];
const barWidth = 16;
const barToBarGap = 8;
const barStyle = {
  width: 16,
  display: "block",
  position: "absolute",
  bottom: 0,
  left: 0,
  borderRadius: 4,
};

// const barToCircleGap = 10;
// const circleStyle = {
//   width: 14,
//   height: 14,
//   display: "block",
//   position: "absolute",
//   bottom: 0,
//   left: 1,
//   borderRadius: 7,
// };
// const circleBottomPropertySeries = [
//   maxBarHeight + barToCircleGap,
//   minBarHeight + barToCircleGap,
//   maxBarHeight + barToCircleGap,
// ];
// const circleBackgroundColors = ["#C9F4FF", "#5DDDFF", "#27D1FF", "#00C1F3"];

const items = [0, 1, 2, 3];
const delaySeries = [0, 0.12, 0.24, 0.36];
const barBackgroundColors = ["#DDCDEF", "#A989CA", "#6D36A5", "#541299"];

const Loader = (props) => {
  return (
    <LoaderContainer {...props}>
      <div style={{ position: "relative" }}>
        {items.map((item, index) => {
          return (
            <motion.div
              key={index}
              animate={{ height: barHeightPropertySeries }}
              transition={{
                duration: 1,
                repeat: Infinity,
                delay: delaySeries[index],
              }}
              style={{
                ...barStyle,
                left: barStyle.left + index * (barWidth + barToBarGap),
                backgroundColor: barBackgroundColors[index],
              }}
            />
          );
        })}
      </div>
    </LoaderContainer>
  );
};

export default Loader;

/* This can be added if we figure out line animation part as per design */
/* <motion.div
              style={{
                ...circleStyle,
                left: circleStyle.left + index * (barWidth + barToBarGap),
                backgroundColor: circleBackgroundColors[index],
              }}
              animate={{
                bottom: circleBottomPropertySeries,
              }}
              transition={{
                duration: 1,
                repeat: Infinity,
                delay: delaySeries[index],
              }}
            /> */
