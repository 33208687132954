import { z } from "zod";
import useForm from "core/hooks/useForm";
import Error from "core/components/Error";
import Field from "core/components/Field";
import Form from "core/components/Form/Form";
import { validate, noDuplicateArrayItems, validUrls } from "common/validation";

const formSchema = z.object({
  callbackUrls: z
    .array(z.string().min(3))
    .refine((value) => validUrls(value) !== false, {
      message: "Invalid url string",
    })
    .optional(),
});

const OrgIntegrationsForm = ({
  onSubmit,
  submitLabel = "Submit",
  initialValues,
  isFetching = false,
  isSubmitting,
  onCancel,
  error,
}) => {
  const {
    formValues,
    errors,
    onFormSubmit,
    onFieldChange,
    isDisabled,
    setIsFormDisabled,
  } = useForm({
    placeholderValues: { callbackUrls: [] },
    initialValues,
    disabledUntilTouched: true,
    onSubmit,
    formSchema,
    isFetchingInitialValues: isFetching,
  });

  return (
    <Form
      onSubmit={onFormSubmit}
      submitLabel={submitLabel}
      isFetching={isFetching}
      isSubmitting={isSubmitting}
      disabled={isDisabled}
    >
      <Error>{error}</Error>

      <Field
        type="text"
        id="callbackUrls"
        labelText="Callback URLs"
        placeholder="Enter callback URLs"
        showSkeleton={isFetching}
        isMultiValue={true}
        isRequired={false}
        value={formValues.callbackUrls}
        error={errors.callbackUrls}
        onChange={onFieldChange("callbackUrls")}
        setIsFormDisabled={setIsFormDisabled}
        validator={validate(
          noDuplicateArrayItems(),
          validUrls(formValues.callbackUrls)
        )}
      />
    </Form>
  );
};

export default OrgIntegrationsForm;
